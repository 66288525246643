import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { editStatus } from '../redux/state/status';

interface TypingMessageProps {
    owner: 'user' | 'bot' | 'agent' | 'system' | 'null';
    name: string;
    isNewOwner: boolean;
}

const TypingMessage = (props: TypingMessageProps) => {

    const [dots, setDots] = useState('...');
    const [showWaitText, setShowWaitText] = useState(false);

    const botChatBoxBgColor = useSelector(
        (state: { settings: { botChatBoxBgColor: string } }) =>
            state.settings.botChatBoxBgColor
    );
    const botTextColor = useSelector(
        (state: { settings: { botTextColor: string } }) =>
            state.settings.botTextColor
    );

    const botAvatar = useSelector(
        (state: { settings: { botAvatar: string } }) => state.settings.botAvatar
    );

    const isBotTyping = useSelector(
        (state: { status: { isBotTyping: Boolean } }) =>
            state.status.isBotTyping
    );

    const delayResponseAlert = useSelector(
        (state: { systemMessages: { delay_response_alert: { message: string } } }) =>
            state.systemMessages.delay_response_alert.message
    );

    const botFontSize = useSelector(
        (state: { settings: { botFontSize: number } }) =>
            state.settings.botFontSize
    );

    const fontSizesRef = ['xs', 'sm', 'base', 'lg', 'xl'];
    const fontSizes = [
        fontSizesRef[0 + botFontSize],
        fontSizesRef[1 + botFontSize],
        fontSizesRef[2 + botFontSize],
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prev) => {
                if (prev.length < 3) {
                    return prev + '.';
                }
                return '';
            });
        }, 500); // Adjust the speed of the animation

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        // Trigger "Please wait longer" after 30 seconds
        let timer : any

        if(isBotTyping){
            timer = setTimeout(() => {
                setShowWaitText(true);
            }, 30000);
        } else {
            // Clear wait text if bot stops typing
            setShowWaitText(false);
        }

        // Clear the timer when the component unmounts
        return () => clearTimeout(timer);

    }, [isBotTyping]);

    if (props.owner === "null") {
        return null; // or <></> if you prefer using a fragment
    }

    return (
        <div
            className={classNames(
                'flex w-full items-start justify-start py-0.5 font-medium z-10 px-2',

                {
                    'flex-row': 
                        props.owner === 'bot' || props.owner === 'agent',
                },
                {
                    'flex-row-reverse': 
                        props.owner === 'user',
                }
            )}
        >
            {/* Always render avatar */}
            <img
                src={props.isNewOwner
                    ? "https://scontent.fkul3-4.fna.fbcdn.net/v/t39.30808-6/241286758_10159729502174429_2950282959013948886_n.jpg?_nc_cat=106&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=euJfafbdwI4AX8s_l2l&_nc_ht=scontent.fkul3-4.fna&oh=00_AT8Jk2bfjyqt95Im0gdlpsocYd8tHq-eTaweaf19FJE4hQ&oe=621B6748"
                    : botAvatar}
                alt="Avatar"
                className='w-8 h-8 medium:w-12 medium:h-12 object-fill mt-4 rounded-full shadow-lg'
            />
    
            {/* Always render typing message */}
            <div
                className={classNames(
                    'flex flex-col text-xs max-w-xl mx-2 w-full',
                    { 'items-start': props.owner === 'bot' || props.owner === 'agent' },
                    { 'items-end': props.owner === 'user' }
                )}
            >
                <div className="text-xs mobile-s:text-xs sm:text-sm font-normal text-slate-500 mt-2 invisible">
                    {props.name}
                </div>
                <div
                    className={classNames(
                        `w-fit max-w-90pc font-base px-4 py-2 rounded-lg inline-block break-words shadow-md chat--disappear active flex flex-wrap whitespace-normal`,
                        {
                            [`text-${fontSizes[0]} small:text-${fontSizes[0]} medium:text-${fontSizes[1]} large:text-${fontSizes[2]}`]: true
                        }
                    )}
                    style={{
                        backgroundColor: `${botChatBoxBgColor ? botChatBoxBgColor.slice(0, 7) : 'transparent'}`,
                        color: `${botTextColor ? botTextColor.slice(0, 7) : 'inherit'}`,
                    }}
                >
                    {/* Typing animation */}
                    <li 
                        style={{ listStyleType: 'none'}}
                    >
                        <div>
                            <span>
                                {showWaitText && (
                                        <span
                                        style={{ marginRight: '8px' }}
                                    >
                                            {delayResponseAlert}
                                        </span>
                                    )}
                                {/* Typing animation dots */}
                                <span style={{
                                    display: 'inline-block',
                                    width: '6px',
                                    height: '6px',
                                    marginRight: '5px',
                                    borderRadius: '50%',
                                    backgroundColor: botTextColor,
                                    animation: 'wave 1.2s infinite ease-in-out',
                                }}>
                                </span>
                                <span style={{
                                    display: 'inline-block',
                                    width: '6px',
                                    height: '6px',
                                    marginRight: '5px',
                                    borderRadius: '50%',
                                    backgroundColor: botTextColor,
                                    animation: 'wave 1.2s infinite ease-in-out 0.2s',  // Delayed by 0.2s
                                }}>
                                </span>
                                <span  style={{
                                    display: 'inline-block',
                                    width: '6px',
                                    height: '6px',
                                    marginRight: '5px',
                                    borderRadius: '50%',
                                    backgroundColor: botTextColor,
                                    animation: 'wave 1.2s infinite ease-in-out 0.4s',  // Delayed by 0.4s
                                }}>
                                </span>

                                {/* Hidden assistive text for accessibility */}
                                {/* <span className="slds-assistive-text">The chat visitor is typing</span> */}
                            </span>
                        </div>

                        {/* Keyframes for wave animation */}
                        <style>{`
                            @keyframes wave {
                                0%, 40%, 100% {
                                    transform: translateY(0);
                                }
                                20% {
                                    transform: translateY(-10px);
                                }
                                60% {
                                    transform: translateY(-5px);
                                }
                            }
                        `}</style>
                    </li>
                </div>
            </div>
        </div>
    );    
};

TypingMessage.propTypes = {};

export default TypingMessage;

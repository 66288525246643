import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loaders from '../../component/Loaders';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { editStatus } from '../../redux/state/status';
import { addDialog } from '../../redux/state/dialog';
import { useCacheService } from '../../hook/useCacheService';
import { useSocket } from '../../hook/useSocket';

interface Props {
    height: number;
}

const QueueModal = (props: Props) => {
    const dispatch = useDispatch();
    //const { sendChatEndEvent } = useSocket();
    const socket = useSocket();
    const { endSession } = useCacheService();
    const [isCancel, setIsCancel] = useState(false);

    const sessionId = useSelector(
        (state: { session: { sessionId: string } }) => state.session.sessionId
    );

    const botName = useSelector(
        (state: { settings: {botName: string}}) =>
            state.settings.botName
    );

    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );

    const isStartAgent = useSelector(
        (state: { session: { isStartAgent: boolean } }) =>
            state.session.isStartAgent
    );

    const enableOfflineForm = useSelector(
        (state: { settings: { enableOfflineForm: Boolean } }) =>
            state.settings.enableOfflineForm
    );

    const useExternalLinkForOfflineForm = useSelector(
        (state: { settings: { useExternalLinkForOfflineForm: Boolean } }) =>
            state.settings.useExternalLinkForOfflineForm
    );

    const externalLinkForOfflineForm = useSelector(
        (state: { settings: { externalLinkForOfflineForm: string } }) =>
            state.settings.externalLinkForOfflineForm
    );

    const queueStatus = useSelector(
        (state: { status: { queueStatus: String } }) => state.status.queueStatus
    );
    const queueNumber = useSelector(
        (state: { status: { queueNumber: number } }) => state.status.queueNumber
    );
    const queueMessage = useSelector(
        (state: { systemMessages: { queue_message: { message: String } } }) =>
            state.systemMessages.queue_message.message
    );
    const queueGreetingMessage = useSelector(
        (state: {
            systemMessages: { queue_greeting_message: { message: String } };
        }) => state.systemMessages.queue_greeting_message.message
    );
    const queueOfflineFormButton = useSelector(
        (state: {
            systemMessages: { queue_offline_form_button: { message: String } };
        }) => state.systemMessages.queue_offline_form_button.message
    );
    const botButtonColor = useSelector(
        (state: { settings: { botButtonColor: string } }) =>
            state.settings.botButtonColor
    );

    const chatId = useSelector(
        (state: { session: { chatId: string } }) => state.session.chatId
    );

    const laAffinityToken = useSelector(
        (state: { session: { laAffinityToken: string } }) =>
            state.session.laAffinityToken
    );

    const laSessionKey = useSelector(
        (state: { session: { laSessionKey: string } }) =>
            state.session.laSessionKey
    );

    const isChatAlive = useSelector(
        (state: { status: { isChatAlive: boolean } }) =>
            state.status.isChatAlive
    );

    useEffect(() => {
        
        if (isCancel && laAffinityToken && laSessionKey) {
            console.log('end from queue modal', isCancel, laAffinityToken, laSessionKey);
            socket.sendChatEndEvent({
                chatId: chatId,
                affinityToken: laAffinityToken,
                key: laSessionKey,
            });
        }
    }, [isCancel, laAffinityToken, laSessionKey]);

    // useEffect(() => {
        
    //     if (queueStatus === "queueing" && isStartAgent == true){
    //         window.addEventListener("unload", handleUnloadTab);
    //     }

    //     return () => {
    //         window.removeEventListener('unload', handleUnloadTab);
    //     };
    // }, [queueStatus, isStartAgent]);

    // const handleUnloadTab = () => {
        
    //     console.log("Refreshed or closed tab during queue page");

    //     let logQueueNumber = queueNumber + 1;

    //     socket.sendUploadChatEvent({
    //         chatId: chatId,
    //         speaker: 'Client',
    //         speakerName: username,
    //         datetime: new Date().toJSON(),
    //         message: "Refreshed or closed tab during queue page. Member queue position at " + logQueueNumber.toString(),
    //     });

    //     endSession(sessionId);
    //     dispatch(editStatus({ name: 'queueStatus', value: 'canceled' }));
    //     setIsCancel(true);
    //     dispatch(
    //         addDialog({
    //             task: 'ended',
    //             type: 'action',
    //         })
    //     );
    // }

    const handleCancelQueue = () => {

        console.log("Web-to-Case button pressed during queue page");

        let logQueueNumber = queueNumber + 1;

        socket.sendUploadChatEvent({
            chatId: chatId,
            speaker: 'Client',
            speakerName: username,
            datetime: new Date().toJSON(),
            message: "Web-to-Case button pressed during queue page. Member queue position at " + logQueueNumber.toString(),
            is_system: true,
        });


        dispatch(editStatus({ name: 'queueStatus', value: 'canceled' }));
        setIsCancel(true);
        dispatch(
            addDialog({
                task: 'ended',
                type: 'action',
            })
        );
        dispatch(
            addDialog({
                task: 'offlineForm',
                type: 'action',
            })
        );
        endSession(sessionId);
        dispatch(
            editStatus({
                name: 'isIframeModal',
                value:
                    true && enableOfflineForm && useExternalLinkForOfflineForm,
            })
        );
        dispatch(
            editStatus({
                name: 'iframeUrl',
                value: externalLinkForOfflineForm,
            })
        );

    };
    return (
        <div
            style={{ height: props.height }}
            className={classNames(
                'absolute w-full bg-white flex justify-center items-center overflow-auto z-20 top-0',
                {
                    hidden: queueStatus == null || queueStatus == 'canceled' || queueStatus == 'established' || queueStatus == 'failed' || !isChatAlive
                }
            )}
        >
            {queueStatus === 'loading' &&
                new Loaders('bg-slate-300', 'wave').getLoader()}
            {queueStatus === 'queueing' && (
                <>
                    <div className="w-90pc medium:w-50pc text-sm medium:text-lg text-center relative">
                        <p>{queueGreetingMessage}</p>
                        <div className="relative flex justify-center items-center py-8">
                            <div
                                style={{
                                    backgroundColor: botButtonColor ? botButtonColor.slice(0, 7) : '',
                                }}
                                className="absolute w-[80px] h-[80px] m-auto rounded-full shadow-md shadow-inner-md flex justify-center items-center"
                            >
                                <p className='text-center text-4xl medium:text-5xl font-bold text-white'>{queueNumber + 1}</p>
                            </div>
                            <div style={{borderTopColor: botButtonColor}} className="queueLoader"></div>
                        </div>

                        <p>
                            {queueMessage.replace(
                                /(\{).+?(\})/g,
                                (queueNumber + 1).toString()
                            )}
                        </p>

                        <button
                            onClick={handleCancelQueue}
                            className="mt-16 p-4 rounded-lg bg-gray-300 text-white shadow-md"
                        >
                            {queueOfflineFormButton}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

QueueModal.propTypes = {};

export default QueueModal;
